import { Link } from '@remix-run/react';
import { forwardRef } from 'react';

import { ButtonInner, getInnerButtonParentClasses } from 'aslan';

import type { LinkProps } from '@remix-run/react';
import type { ButtonInnerProps } from 'aslan';

type AnchorProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

type ButtonLinkProps = ButtonInnerProps & { buttonClassName?: string };

/**
 * This function is used to determine whether to render a Remix Link or a regular anchor element.
 * If the to prop is a string and it contains a colon, it's an external link, so use a regular anchor.
 * If the href prop is a string and it contains a colon or starts with a hash, it's an external link,
 * so use a regular anchor.
 * Otherwise, use a Remix Link.
 * @param props - The props to use for this component.
 * @param ref - The ref to use for this component.
 * @returns A Remix Link or a regular anchor element.
 */

const AnchorOrLink = forwardRef<
  HTMLAnchorElement,
  AnchorProps & {
    reload?: boolean;
    to?: LinkProps['to'];
    prefetch?: LinkProps['prefetch'];
  }
>(function AnchorOrLink(props, ref) {
  const {
    to,
    href,
    download,
    reload = false,
    prefetch,
    children,
    ...rest
  } = props;
  let toUrl = '';
  let shouldUserRegularAnchor = reload || download;

  if (!shouldUserRegularAnchor && typeof href === 'string') {
    shouldUserRegularAnchor = href.includes(':') || href.startsWith('#');
  }

  if (!shouldUserRegularAnchor && typeof to === 'string') {
    toUrl = to;
    shouldUserRegularAnchor = to.includes(':');
  }

  if (!shouldUserRegularAnchor && typeof to === 'string') {
    toUrl = to;
    shouldUserRegularAnchor = to.includes(':');
  }

  if (!shouldUserRegularAnchor && typeof to === 'object') {
    toUrl = `${to.pathname ?? ''}${to.hash ? `#${to.hash}` : ''}${
      to.search ? `?${to.search}` : ''
    }`;
    shouldUserRegularAnchor = to.pathname?.includes(':');
  }

  if (shouldUserRegularAnchor) {
    return (
      <a {...rest} download={download} href={href ?? toUrl} ref={ref}>
        {children}
      </a>
    );
  }

  return (
    <Link prefetch={prefetch} to={to ?? href ?? ''} {...rest} ref={ref}>
      {children}
    </Link>
  );
});

// This is a button that can be used as a link, <a> element or Remix Link
export const ButtonLink = forwardRef<
  HTMLAnchorElement,
  React.ComponentPropsWithRef<typeof AnchorOrLink> & ButtonLinkProps
>(function ButtonLink(
  {
    children,
    buttonClassName,
    className,
    color,
    hasShadow,
    icon,
    isFullWidth,
    isOutline,
    isRounded,
    size,
    ...rest
  },
  ref
) {
  return (
    <AnchorOrLink
      ref={ref}
      className={getInnerButtonParentClasses(
        `${className || ''} ${isFullWidth ? 'w-full' : ''}`
      )}
      {...rest}
    >
      <ButtonInner
        className={buttonClassName}
        color={color}
        hasShadow={hasShadow}
        icon={icon}
        isFullWidth={isFullWidth}
        isOutline={isOutline}
        isRounded={isRounded}
        size={size}
      >
        {children}
      </ButtonInner>
    </AnchorOrLink>
  );
});
